import React from 'react';
import '../../App.css';
import './Ti.css';
import Footer from '../Footer';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Button } from '../button';
import {Helmet} from "react-helmet";

function Ti () {

    const { Ti } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Ti]);
    return (
        <>
          <Helmet>
          <meta charSet='utf-8'/>
          <title>Visual Hole Agency | Transformación Digital</title>
          <meta name="description" content= "Expertos en análisis de datos, big data, datawarehouse, para alcanzar los objetivos de la empresa. Power BI. Business Intelligence, Inteligencia de negocios " />
          <meta name="robots" content= "index, follow"></meta>
        </Helmet>

     
        <div className='ti-home'>
             <h1>Transformación Digital</h1> 
             <p> El objetivo es conquistar no solo la mente del consumidor, sino también su corazón.</p> 
        </div>

        <div className='bi-row'>
          <div className='bi-col'>
                        <h1>Qué es?</h1>
          </div>
          <div className='bi-col-r'>
           
                        <p>Es la habilidad para transformar los datos en información, y la información en conocimiento, 
                          de forma que se pueda optimizar el proceso de toma de decisiones en los negocios.
                           </p>
                       
          </div>
        </div>

        <div className='bi-row'>
          <div className='bi-col'>
          <h1>Por qué es importante?</h1>
                        
          </div>
          <div className='bi-col-r'>
          <p>Muestra datos actuales e históricos dentro de su contexto empresarial para que las empresas tomen mejores
                           decisiones. Los analistas pueden aprovechar BI para proporcionar puntos de referencia de rendimiento y de la competencia
                            para que la organización funcione de manera más fluida y eficiente.
                        </p>
          </div>
        </div>

        <div className='bi-row'>
          <div className='bi-col'>
                        <h1>Cómo funciona?</h1>
          </div>
          <div className='bi-col-r'>
                        <p>Recopilando los datos necesarios desde el datawarehouse, se analizan y determinan las acciones que deben tomarse para que alcancen los objetivos. 
                           </p>
          </div>
        </div>



<div className='bi-centro'>
<div className='bi-title'>
  <h1>Qué podemos hacer por ti?</h1>
</div>
<div className='bi-contenido'>
<h2>Crear una cultura controlada por datos con inteligencia empresarial para todos</h2>
  <p>
Permita que todo el mundo, en todos los niveles de su organización, tome decisiones con confianza usando el análisis de última hora.
</p>
</div>
</div>

<div className='bi-row-f'>
          <div className='bi-col-f'>
                        <h2>Combinamos visualizaciones y aplicaciones para generar acciones</h2>
                        <p>Incorporamos IA a tus aplicaciones y procesos, además de conclusiones obtenidas de Power BI, 
                          Microsoft Graph y los servicios cognitivos.</p>
                          <h2>Creamos soluciones mejoradas para aumentar la productividad </h2>
                          <p>Desarrollamos rápidamente aplicaciones con Power Apps que puedes usar en diferentes dispositivos y en Microsoft Teams.</p>
                           <h2>Agregamos la automatización al negocio fácilmente </h2>  
                           <p>Automatizamos tus flujos de trabajo, habilita la lógica de negocios para simplificar las tareas y 
                            conecta tus procesos con Microsoft Power Automate.</p>
          </div>
          <div className='bi-col-f-r'>
          <div className='bi-colf-r'>
          <div className='bi-imagen-row-f'> 
                <img class="bi-imagen-pat-f" 
                src="https://visual-hole.imgix.net/pbpa.gif" 
                alt="Generic placeholder image"/>
         </div>
          </div>
          </div>
        </div>

        <div className='bi-centro'>
<div className='bi-title'>
  <h1>Desarrollo de Software</h1>
</div>
</div>
<div className='bi-row3'>
          <div className='bi-col3'>
            <div className='bi-col3-content'>
                        <p>Desarrollo fácil, flexible y accesible para todos</p>
                        <p>Apps Móviles
 <li>Nativas para iOS con Swift</li>
 <li>Nativas para Android con Kotlin</li>
<li>Híbridas iOS y Android con flutter </li> 
</p>
          </div>
          </div>
          <div className='bi-col3-r'>
          <div className='bi-imagen-row'> 
                <img class="bi-imagen-pat" 
                src="https://visual-hole.imgix.net/appmovilv2.gif" 
                alt="Imagen de aplicacion movil en iphone14s"/>
         </div>
          </div>
        </div>

        <div className='bi-title'>
  <h1>Tecnologias que usamos</h1>
</div>

 <div class="row4">
 <div class="column4"><p><h3>Front End</h3>
 <li>React </li>
 <li>JSAngular</li>
 <li>Flutter</li>
</p></div>
 <div class="column4"><p><h3>Back End</h3>
 <li>Typescript </li>
 <li>Javascript con NodeJS</li>
</p></div>
  <div class="column4"><p><h3>Bases de Datos</h3>
 <li>PostgreSQL</li>
 <li>MySQL</li>
<li>MSSQL</li> 
</p></div>
  <div class="column4"><p><h3>APIs</h3>
 <li>Rest</li>
 <li>NodeJS</li>
<li>Asp.net</li> 
</p></div>
<div class="column4"><p><h3>DevOps</h3>
 <li>Azure</li>
 <li>AWS</li>
</p></div>
</div> 

<div className='bi-row'>
          <div className='bi-col'>
                        <h1>Diseño  ux/ui </h1>
          </div>
          <div className='bi-col-r'>
           
                        <p>Trabajamos sobre el diseño de experiencia de usuario e interfases y creamos prototipos con las mejores prácticas.
                           Nuestro trabajo se centra en el Diseño Web y Mobile, el diseño de interfaz de usuario y el testeo de usabilidad. 
                           </p>
                       
          </div>
        </div>
        <div className='bi-row'>
          <div className='bi-col'>
                        <h1>Diseño  de interacción </h1>
          </div>
          <div className='bi-col-r'>
           
                        <p>Cuando los usuarios están comprando en su tienda online, ¿cuál es el siguiente paso después de añadir un artículo a su carrito?
                           ¿Si se produce un error, todo lo que han hecho será eliminado? Identificamos, evaluamos y diseñamos las interacciones 
                           que sus usuarios puedan tener con sus productos. 
                           </p>
                       
          </div>
        </div>

        <div className='bi-centro'>
<div className='bi-title'>
  <h1>Arquitectura de la información</h1>
</div>
<div className='bi-contenido'>
<h2>La gente debe ser capaz de encontrar rápido la información que están buscando.</h2>
  <p>
  Su sitio web o aplicación debe estar organizado para que sea consistente, intuitivo y que refleja los objetivos de su organización.
   Nuestros arquitectos de la información están entrenados en la organización de la información y en las estructuras lógicas que tienen
    sentido específicamente a sus usuarios.
</p>
</div>
</div>

<div className='bi-centro-centro'>
      <div className='bi-solicita'><a target="_blank" href="https://api.whatsapp.com/send?phone=50761107470&text=Quiero%20sesion%20bi"> <button className='bt' >Solicita una sesión</button> </a></div>
        </div>
        <Footer/>
        </>
    ); 
}

export default Ti;