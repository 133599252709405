import React from 'react';
import '../../App.css';
import './Bi.css';
import Footer from '../Footer';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Button } from '../button';
import {Helmet} from "react-helmet";

function Bi () {

    const { Bi } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Bi]);
    return (
        <>
          <Helmet>
          <meta charSet='utf-8'/>
          <title>Visual Hole Agency | Business Intelligence</title>
          <meta name="description" content= "Expertos en análisis de datos, big data, datawarehouse, para alcanzar los objetivos de la empresa. Power BI. Business Intelligence, Inteligencia de negocios " />
          <meta name="robots" content= "index, follow"></meta>
        </Helmet>
        <div className='header'>
        <h1>Business intelligence</h1> 
        </div>

        <div className='bi-row'>
          <div className='bi-col'>
                        <h1>Qué es?</h1>
          </div>
          <div className='bi-col-r'>
           
                        <p>Es la habilidad para transformar los datos en información, y la información en conocimiento, 
                          de forma que se pueda optimizar el proceso de toma de decisiones en los negocios.
                           </p>
                       
          </div>
        </div>

        <div className='bi-row'>
          <div className='bi-col'>
          <h1>Por qué es importante?</h1>
                        
          </div>
          <div className='bi-col-r'>
          <p>Muestra datos actuales e históricos dentro de su contexto empresarial para que las empresas tomen mejores
                           decisiones. Los analistas pueden aprovechar BI para proporcionar puntos de referencia de rendimiento y de la competencia
                            para que la organización funcione de manera más fluida y eficiente.
                        </p>
          </div>
        </div>

        <div className='bi-row'>
          <div className='bi-col'>
                        <h1>Cómo funciona?</h1>
          </div>
          <div className='bi-col-r'>
                        <p>Recopilando los datos necesarios desde el datawarehouse, se analizan y determinan las acciones que deben tomarse para que alcancen los objetivos. 
                           </p>
          </div>
        </div>

<div className='bi-centro'>
<div className='bi-title'>
  <h1>Herramientas para Business intelligence</h1>
</div>
</div>
<div className='bi-row3'>
          <div className='bi-col3'>
            <div className='bi-col3-content'>
                        <p>Durante 14 años consecutivos, Gartner ha reconocido a Microsoft como líder de plataformas de business intelligence y análisis en su Cuadrante Mágico.</p>
          </div>
          </div>
          <div className='bi-col3-r'>
          <div className='bi-imagen-row'> 
                <img class="bi-imagen-pat" 
                src="https://visual-hole.imgix.net/bimq.png" 
                alt="Generic placeholder image"/>
         </div>
          </div>
        </div>

<div className='bi-centro'>
<div className='bi-title'>
  <h1>Qué podemos hacer por ti?</h1>
</div>
<div className='bi-contenido'>
<h2>Crear una cultura controlada por datos con inteligencia empresarial para todos</h2>
  <p>
Permita que todo el mundo, en todos los niveles de su organización, tome decisiones con confianza usando el análisis de última hora.
</p>
</div>
</div>

<div className='bi-row-f'>
          <div className='bi-col-f'>
                        <h2>Obtenga análisis en autoservicio a escala empresarial</h2>
                        <p>Reduzca el coste agregado, la complejidad y los riesgos de 
                          seguridad de varias soluciones con una plataforma de análisis que se escala desde los individuos hasta la organización en su conjunto.</p>
                          <h2>Utilizar herramientas inteligentes para obtener resultados sólidos</h2>
                          <p>Encuentre y comparta conocimientos significativos gracias a una perfecta integración con Excel y a cientos de visualizaciones
                             de datos, funcionalidades de IA nativas y conectores de datos prediseñados y personalizados.</p>
                           <h2>Ayude a proteger sus datos de análisis</h2>  
                           <p>Mantenga sus datos protegidos con funcionalidades de seguridad de datos líderes en el sector, como el etiquetado de
                              confidencialidad, el cifrado de extremo a extremo y el control del acceso en tiempo real.</p>
          </div>
          <div className='bi-col-f-r'>
          <div className='bi-colf-r'>
          <div className='bi-imagen-row-f'> 
                <img class="bi-imagen-pat-f" 
                src="https://visual-hole.imgix.net/bipb.jpg" 
                alt="Generic placeholder image"/>
         </div>
          </div>
          </div>
        </div>
<div className='bi-centro-centro'>
      <div className='bi-solicita'><a target="_blank" href="https://api.whatsapp.com/send?phone=50761107470&text=Quiero%20sesion%20bi"> <button className='bt' >Solicita una sesión</button> </a></div>
        </div>
        <Footer/>
        </>
    ); 
}

export default Bi;