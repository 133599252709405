import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Simple5 from "../Simple5";
import "react-multi-carousel/lib/styles.css";


function Bambolina () {
    const { Bambolina } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Bambolina]);

        

    return (
        <>

        <div className='wraper'>
        <div className='box1'>
        <h2>Manual de marca para Bambolina.</h2>
        <p>Bambolina es una marca de accesorios sofisticado para esas mujeres que buscan algo elegante y único para ellas.</p>
        <h3>Fecha</h3>
        <p>Julio, 2021</p>
       </div>

       <div className='box2'>
       <div className='slider'>
       <span>  <Simple5 deviceType="desktop" />  </span>
        </div>
        
       </div>
</div>






        <Footer/>
        </>
    );
}

export default Bambolina;