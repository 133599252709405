import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Simple from "../Simple9";
import "react-multi-carousel/lib/styles.css";


function Santarita () {
    const { Santarita } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Santarita]);

        

    return (
        <>

        <div className='wraper'>
        <div className='box1'>
        <h2>Manual de marca para Santa Rita.</h2>
        <p>La Finca Santa Rita cosecha sus productos en armonía con la naturaleza. Ofrecen diferentes productos artesanales.</p>
        <h3>Fecha</h3>
        <p>Marzo, 2022</p>
       </div>

       <div className='box2'>
       <div className='slider'>
       <span>  <Simple deviceType="desktop" />  </span>
        </div>
        
       </div>
</div>






        <Footer/>
        </>
    );
}

export default Santarita;