import React from 'react'
import { Link } from 'react-router-dom';
import './Towcol.css';
import { Button } from './button';

function Towcol() {
  return (
    <>
  
  <div class="container-towcol">
  <h1>¿Estás listo para transformar tu negocio?</h1>
  <div class="row-towcol">
    <div class="col-sm">
    <img class="imagen-col" 
    src="https://visual-hole.imgix.net/escritorio.jpg" 
    alt="Generic placeholder image"/>
    </div>
    <div class="col-sm-r">
    
     <p>Visual Hole Agency es un estudio de diseño, comunicación, gestión y transformación digital
       ubicado en Panamá. Damos vida a marcas de diversas industrias, gestionamos redes sociales,
        ofrecemos diferentes servicios de diseño, creación de páginas web y transformación digital. 
        Con nuestros servicios, quiero ayudarte a atraer a los clientes adecuados y lograr un éxito comercial a largo plazo.</p>
       <div className='sobremi-button'>{Button && < Button to='/Sobremi' buttonstyle ='btn--outline'> Sobre nosotros</Button>}</div>
    </div>
    
</div>
<div className='col-full'> 
    <h1>¿Cómo podemos ayudarte? </h1>
    <h2>Quiero conocer la historia de tu marca</h2>
    <p>Para poder comunicar una buena estrategia, necesito saber tu historia y cómo podemos sacar provecho de ella.<br/> 
      <br/>Nos reunimos de modo online, hablamos, te escucho, y anoto todo lo que necesitas. <br/>
      <br/>Luego seguimos con los siguientes pasos dependiendo del servicio que requieres. <br/>
      <br/>Buscamos impulsar a emprendedores, medianas y grandes empresas a que puedan conseguir un negocio visible, 
      estético y rentable, asesorándolos y diseñando una marca única, funcional y moderna.</p>
      <div className='row-button-home'>{Button && < Button to='/Asesoria' buttonstyle ='btn--outline'> Asesoría</Button>}</div>
      
      
      </div>
      
             
          </div>

          

    
    </>
    
  )
  
}

export default Towcol