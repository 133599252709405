import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

function Opalo () {
    const { Opalo } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Opalo]);
      

    return (
        <>
        <div className='contenido-pr'>
        <div className='row-pr'>
        <div className='col-pr'>
        <h2>Identidad visual para <br/> Ópalo</h2>
        <p>Ópalo es una marca venezolana de accesorios y joyas exclusivas. </p>
        <h3>Fecha</h3>
        <p>Enero, 2020</p>
   
        
        </div>
        <div className='col-pr-r'>
        <div className='imagen-pack-row-pr'> 
                <img class="imagen-pack-row-1-pr" 
                src="/images/portafolio/opalo1.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        

        </div>
        
        </div>
        <Footer/>
        </>
    );
}

export default Opalo;