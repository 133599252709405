import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Simple7 from "../Simple7";
import "react-multi-carousel/lib/styles.css";


function Yosfisiofit () {
    const { Yosfisiofit } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Yosfisiofit]);

        

    return (
        <>

        <div className='wraper'>
        <div className='box1'>
        <h2>Manual de marca para Yosfisiofit.</h2>
        <p>Yosfisiofit se enfoca en la fisioterapia y estética. Terapia para el dolor, masaje, drenaje linfático, facial y GuaSha.</p>
        <h3>Fecha</h3>
        <p>Junio, 2021</p>
       </div>

       <div className='box2'>
       <div className='slider'>
       <span>  <Simple7 deviceType="desktop" />  </span>
        </div>
        
       </div>
</div>






        <Footer/>
        </>
    );
}

export default Yosfisiofit;