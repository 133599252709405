import react from 'react';
import './App.css';
import NavBar from './components/NavBar';
import {BrowserRouter as  Router, Routes, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import Bambolina from './components/pages/Bambolina';
import Branding from './components/pages/Branding';
import Contenido from './components/pages/Contenido';
import Asesoria from './components/pages/Asesoria';
import Portafolio from './components/pages/Portafolio';
import Pagina from './components/pages/Pagina';
import Bi from './components/pages/Bi';
import Ti from './components/pages/Ti';
import Sobremi from './components/pages/Sobremi';
import Yosfisiofit from './components/pages/Yosfisiofit';
import Saxifraga from './components/pages/Saxifraga';
import Mercadodevera from './components/pages/Mercadodevera';
import Photozulem from './components/pages/Photozulem';
import Zulemevents from './components/pages/Zulemevents';
import Azucenas from './components/pages/Azucenas';
import Neoseguros from './components/pages/Neoseguros';
import Danielad from './components/pages/Danielad';
import Bloom from './components/pages/Bloom';
import Mirandatroudart from './components/pages/Mirandatroudart';
import Spontaneo from './components/pages/Spontaneo';
import Eliana from './components/pages/Eliana';
import Iluminando from './components/pages/Iluminando';
import Mf from './components/pages/Mf';
import Priscila from './components/pages/Priscila';
import Healthy from './components/pages/Healthy';
import Fc from './components/pages/Fc';
import Eleden from './components/pages/Eleden';
import Opalo from './components/pages/Opalo';
import Musa from './components/pages/Musa';
import Santarita from './components/pages/Santarita';
import Troudart from './components/pages/Troudart';
import Finca from './components/pages/Finca';
import Justi from './components/pages/Justi';
import Prime from './components/pages/Prime';
import Sitemap from './components/sitemap';
import Privacidad from './components/pages/Privacidad';
import Terminos from './components/pages/Terminos';
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <div className="App">
      <Router  >
        
      <NavBar /> 
     
      <Routes>

      <Route path ='/' element = {<Home/>} />
      
      <Route path ='/Branding' element ={<Branding/>}/>
      <Route path ='/Contenido' element ={<Contenido/>}/>
      <Route path ='/Asesoria' element ={<Asesoria/>}/>
      <Route path ='/Portafolio' element ={<Portafolio/>}/>
      <Route path ='/Pagina' element ={<Pagina/>}/>
      <Route path ='/Bi' element ={<Bi/>}/>

      <Route path ='/Sobremi' element ={<Sobremi/>}/>
      <Route path ='/Saxifraga' element ={<Saxifraga/>}/>
      <Route path ='/Photozulem' element ={<Photozulem/>}/>
      <Route path ='/Zulemevents' element ={<Zulemevents/>}/>
      <Route path ='/Azucenas' element ={<Azucenas/>}/>
      <Route path ='/Neoseguros' element ={<Neoseguros/>}/>
      <Route path ='/Bloom' element ={<Bloom/>}/>
      <Route path ='/Danielad' element ={<Danielad/>}/>
      <Route path ='/Mirandatroudart' element ={<Mirandatroudart/>}/>
      <Route path ='/Bambolina' element ={<Bambolina/>}/>
      <Route path ='/Mercadodevera' element ={<Mercadodevera/>}/>
      <Route path ='/Yosfisiofit' element ={<Yosfisiofit/>}/>
      <Route path ='/Spontaneo' element ={<Spontaneo/>}/>
      <Route path ='/Eliana' element ={<Eliana/>}/>
      <Route path ='/Iluminando' element ={<Iluminando/>}/>
      <Route path ='/Mf' element ={<Mf/>}/>
      <Route path ='/Priscila' element ={<Priscila/>}/>
      <Route path ='/Healthy' element ={<Healthy/>}/>
      <Route path ='/Fc' element ={<Fc/>}/>
      <Route path ='/Eleden' element ={<Eleden/>}/>
      <Route path ='/Opalo' element ={<Opalo/>}/>
      <Route path ='/Musa' element ={<Musa/>}/>
      <Route path ='/Santarita' element ={<Santarita/>}/>
      <Route path ='/Troudart' element ={<Troudart/>}/>
      <Route path ='/Finca' element ={<Finca/>}/>
      <Route path ='/Justi' element ={<Justi/>}/>
      <Route path ='/Prime' element ={<Prime/>}/>
      <Route path ='/Privacidad' element ={<Privacidad/>}/>
      <Route path ='/Terminos' element ={<Terminos/>}/>
      <Route path ='/Ti' element ={<Ti/>}/>
      <Route path="/sitemap" component={Sitemap}/>

      </Routes>
      
      </Router>
     
      <CookieConsent  enableDeclineButton flipButtons  location ="bottom" style={{background:'#fff', textAlign:'justify', color:'#000', fontSize:'10px'}}
      buttonStyle={{color:'#fff' , background:'#cc938c', fontSize:'14px'}}  expires={150}
      buttonText = 'Acepto' declineButtonText='No Acepto' declineButtonStyle={{color:'#fff' , background:'#cc938c', fontSize:'14px', }}>
     
       Su privacidad es importante para nosotros
       Nosotros y nuestros almacenamos o accedemos a información en un dispositivo, tales como cookies, 
       y procesamos datos personales, tales como identificadores únicos e información estándar enviada 
       por un dispositivo, para anuncios y contenido personalizados, medición de anuncios y del contenido e
      información sobre el público, así como para desarrollar y mejorar productos.
      Con su permiso, nosotros y nuestros socios podemos utilizar datos de localización geográfica precisa e 
      identificación mediante las características de dispositivos. Puede hacer clic para otorgarnos su
      consentimiento a nosotros y a nuestros socios para que llevemos a cabo el procesamiento previamente 
      descrito. De forma alternativa, puede hacer clic para denegar su consentimiento o acceder a información 
      más detallada y cambiar sus preferencias antes de otorgar su consentimiento.
      Tenga en cuenta que algún procesamiento de sus datos personales puede no requerir de su consentimiento, 
      pero usted tiene el derecho de rechazar tal procesamiento. Sus preferencias se aplicarán solo a este sitio web.
      Puede cambiar sus preferencias en cualquier momento entrando de nuevo en este sitio web o visitando 
      nuestra política de privacidad. 
      </CookieConsent>
    </div>
  );
}

export default App;
