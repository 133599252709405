import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Simple6 from "../Simple6";
import "react-multi-carousel/lib/styles.css";


function Eliana () {
    const { Eliana } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Eliana]);

        

    return (
        <>

        <div className='wraper'>
        <div className='box1'>
        <h2>Eliana Interior Desing </h2>
        <p>Eliana Interior Desing es un estudio de diseño de interiores. Crea espacios que comunican la esencia de sus clientes. Ofrecen asesorías, diseño y remodelación, interior styling y diseño de eventos en 3D. </p>
        <h3>Fecha</h3>
        <p>Junio, 2021</p>
       </div>

       <div className='box2'>
       <div className='slider'>
       <span>  <Simple6 deviceType="desktop" />  </span>
        </div>
        
       </div>
</div>






        <Footer/>
        </>
    );
}

export default Eliana;