import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

function Mf () {
    const { Mf } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Mf]);
      

    return (
        <>
        <div className='contenido-pr'>
        <div className='row-pr'>
        <div className='col-pr'>
        <h2>Identidad visual para MF Nutrición</h2>
        <p>María Fernanda Álvarez es nutricionista y brinda atención nutricional online y presencial.  </p>
        <h3>Fecha</h3>
        <p>Abril, 2021</p>
   
        
        </div>
        <div className='col-pr-r'>
        <div className='imagen-pack-row-pr'> 
                <img class="imagen-pack-row-1-pr" 
                src="/images/portafolio/mfnutricion1-min.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        

        </div>
        
        </div>
        <Footer/>
        </>
    );
}

export default Mf;