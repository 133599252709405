import React from 'react';
import './Portafolio2.css';
import CardItem from './CardItem';
import { Button } from './button';
function Cards() {
  return (
    <div className='cards'>
      <h1>Portafolio</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
          <CardItem
              src='images/santarita-01.jpg'
              text='Santa Rita'
              
              path='/Santarita'
            />
         
         
          <CardItem
              src='images/mercadovera-01.jpg'
              text='Mercado de Vera'
              
              path='/Mercadodevera'
            />
            <CardItem
              src='images/photozulem-01.jpg'
              text='Katherine Zulem'
             
              path='/Photozulem'
            />
           <CardItem
              src='images/zulemevent-01.jpg'
              text='Zulem Events'
              
              path='/Zulemevents'
            />
            <CardItem
              src='images/fincamariabonita-01.jpg'
              text='Finca Maria Bonita'
             
              path='/Finca'
            />
           </ul>
          <ul className='cards__items'>
           
            
            
          </ul>
          <div className='row-button-home'>{Button && < Button to='/Portafolio'buttonstyle ='btn--outline'> Ver mas</Button>}</div>
       
      
        </div>
      </div>
    </div>
  );
}

export default Cards;
