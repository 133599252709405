import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};
const images = [
    "/images/portafolio/eliana1-min.jpg",
    "/images/portafolio/eliana2-min.jpg",
    "/images/portafolio/eliana3-min.jpg",
    "/images/portafolio/eliana4-min.jpg",
    "/images/portafolio/eliana5-min.jpg",
    "/images/portafolio/eliana6-min.jpg",
    "/images/portafolio/eliana7-min.jpg",
    "/images/portafolio/eliana8-min.jpg",
    "/images/portafolio/eliana9-min.jpg",
    "/images/portafolio/eliana10-min.jpg",
    "/images/portafolio/eliana11-min.jpg",
    "/images/portafolio/eliana12-min.jpg",
    "/images/portafolio/eliana13-min.jpg",
    "/images/portafolio/eliana14-min.jpg",
    "/images/portafolio/eliana15-min.jpg",
    "/images/portafolio/eliana16-min.jpg"
    
];

// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const Simple = ({ deviceType }) => {
  return (
    <Carousel
      ssr
      deviceType={deviceType}
      itemClass="image-item"
      responsive={responsive}
    >
      {images.slice(0, 16).map((image, index) => {
        return (
          <div key={index} style={{ position: "relative" }}>
            <img
              draggable={false}
              alt="text"
              style={{ width: "100%", height: "100%" }}
              src={image}
            />
            <p
              style={{
                position: "absolute",
                left: "100%",
                bottom: 0,
                color: "white",
                transform: " translateX(-50%)"
              }}
            >
              Legend:{index}.
            </p>
          </div>
        );
      })}
    </Carousel>
  );
};

export default Simple;
