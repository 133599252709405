import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

function Saxifraga () {
    const { Saxifraga } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Saxifraga]);
      

    return (
        <>
        <div className='contenido-pr'>
        <div className='row-pr'>
        <div className='col-pr'>
        <h2>Diseno de identidad visual Saxifraga</h2>
        <p>Saxifrga es una boutique de ropa con tallas y medidas personalizadas</p>
        <h3>Fecha</h3>
        <p>Febrero, 2021</p>
   
        
        </div>
        <div className='col-pr-r'>
        <div className='imagen-pack-row-pr'> 
                <img class="imagen-pack-row-1-pr" 
                src="/images/portafolio/saxifraga1.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        

        </div>
        <div className='row-pr'>
        <div className='col-pr'>
        
   
        
        </div>
        <div className='col-pr-r'>
        <div className='imagen-pack-row-pr'> 
                <img class="imagen-pack-row-1-pr" 
                src="/images/portafolio/saxifraga2.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        

        </div>
        </div>
        <Footer/>
        </>
    );
}

export default Saxifraga;