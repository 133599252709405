import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

function Bloom () {
    const { Bloom } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Bloom]);
      

    return (
        <>
        <div className='contenido-pr'>
        <div className='row-pr'>
        <div className='col-pr'>
        <h2>Identidad visual para <br/> Bloom Naturals</h2>
        <p>Bloom Naturals es una marca enfocada en lo natural de la cosmética.</p>
        <h3>Fecha</h3>
        <p>Septiembre, 2021</p>
   
        
        </div>
        <div className='col-pr-r'>
        <div className='imagen-pack-row-pr'> 
                <img class="imagen-pack-row-1-pr" 
                src="/images/portafolio/bloomnaturals1-min.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        

        </div>
        
        </div>
        <Footer/>
        </>
    );
}

export default Bloom;