import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};
const images = [
    "/images/portafolio/iluminando1-min.jpg",
    "/images/portafolio/iluminando2-min.jpg",
    "/images/portafolio/iluminando3-min.jpg",
    "/images/portafolio/iluminando4-min.jpg",
    "/images/portafolio/iluminando5-min.jpg",
    "/images/portafolio/iluminando6-min.jpg",
    "/images/portafolio/iluminando7-min.jpg",
    "/images/portafolio/iluminando8-min.jpg",
    "/images/portafolio/iluminando9-min.jpg",
    "/images/portafolio/iluminando10-min.jpg",
    "/images/portafolio/iluminando11-min.jpg",
    "/images/portafolio/iluminando12-min.jpg",
    "/images/portafolio/iluminando13-min.jpg",
    "/images/portafolio/iluminando14-min.jpg",
    "/images/portafolio/iluminando15-min.jpg",
    "/images/portafolio/iluminando16-min.jpg",
    "/images/portafolio/iluminando17-min.jpg",
    "/images/portafolio/iluminando18-min.jpg",
    "/images/portafolio/iluminando19-min.jpg",
    "/images/portafolio/iluminando20-min.jpg",
    "/images/portafolio/iluminando21-min.jpg",
    "/images/portafolio/iluminando22-min.jpg",
    "/images/portafolio/iluminando23-min.jpg",
    "/images/portafolio/iluminando24-min.jpg",
    "/images/portafolio/iluminando25-min.jpg",
    "/images/portafolio/iluminando26-min.jpg"
    
];

// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const Simple = ({ deviceType }) => {
  return (
    <Carousel
      ssr
      deviceType={deviceType}
      itemClass="image-item"
      responsive={responsive}
    >
      {images.slice(0, 28).map((image, index) => {
        return (
          <div key={index} style={{ position: "relative" }}>
            <img
              draggable={false}
              alt="text"
              style={{ width: "100%", height: "100%" }}
              src={image}
            />
            <p
              style={{
                position: "absolute",
                left: "100%",
                bottom: 0,
                color: "white",
                transform: " translateX(-50%)"
              }}
            >
              Legend:{index}.
            </p>
          </div>
        );
      })}
    </Carousel>
  );
};

export default Simple;
