import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};
const images = [
    "/images/portafolio/azucenas1-min.jpg",
    "/images/portafolio/azucenas2-min.jpg",
    "/images/portafolio/azucenas3-min.jpg",
    "/images/portafolio/azucenas4-min.jpg",
    "/images/portafolio/azucenas5-min.jpg",
    "/images/portafolio/azucenas6-min.jpg",
    "/images/portafolio/azucenas7-min.jpg",
    "/images/portafolio/azucenas8-min.jpg",
    "/images/portafolio/azucenas9-min.jpg",
    "/images/portafolio/azucenas10-min.jpg",
    "/images/portafolio/azucenas11-min.jpg",
    "/images/portafolio/azucenas12-min.jpg",
    "/images/portafolio/azucenas13-min.jpg",
    "/images/portafolio/azucenas14-min.jpg",
    "/images/portafolio/azucenas15-min.jpg",
    "/images/portafolio/azucenas16-min.jpg",
    "/images/portafolio/azucenas17-min.jpg"
    
];

// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const Simple = ({ deviceType }) => {
  return (
    <Carousel
      ssr
      deviceType={deviceType}
      itemClass="image-item"
      responsive={responsive}
    >
      {images.slice(0, 17).map((image, index) => {
        return (
          <div key={index} style={{ position: "relative" }}>
            <img
              draggable={false}
              alt="text"
              style={{ width: "100%", height: "100%" }}
              src={image}
            />
            <p
              style={{
                position: "absolute",
                left: "100%",
                bottom: 0,
                color: "white",
                transform: " translateX(-50%)"
              }}
            >
              Legend:{index}.
            </p>
          </div>
        );
      })}
    </Carousel>
  );
};

export default Simple;
