import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

function Fc () {
    const { Fc } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Fc]);
      

    return (
        <>
        <div className='contenido-pr'>
        <div className='row-pr'>
        <div className='col-pr'>
        <h2>Identidad visual para <br/> Feliz Compras</h2>
        <p>Feliz compras es una tienda online de accesorios, makeup, hogar, calzado ropa y otros productos. </p>
        <h3>Fecha</h3>
        <p>Junio, 2020</p>
   
        
        </div>
        <div className='col-pr-r'>
        <div className='imagen-pack-row-pr'> 
                <img class="imagen-pack-row-1-pr" 
                src="/images/portafolio/felizcompras1-min.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        

        </div>
        
        </div>
        <Footer/>
        </>
    );
}

export default Fc;