import React from 'react';
import './Footer.css';
import { Button } from './button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
        
        </div>
      </div>
        <p className='footer-subscription-text'>
        <div class='social-icons'>

        <a target="_blank" href="https://www.facebook.com/visualholeagency"  class='social-icon-link facebook'>  <i class='fab fa-facebook-f' /> </a>
 
        <a target="_blank" href="https://www.instagram.com/visualhole_"  class='social-icon-link instagram'>  <i class='fab fa-instagram' /> </a>
     
            
          </div>
        </p>
        <div class='footer-link-items'>
        <Link          
              to='/'
             
            > <h5>Visual Hole Agency</h5>
              
            </Link>

            <h5>contacto@visual-hole.com</h5>
            <h5>+50761107470</h5>
           </div>
      
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
          <a href="https://www.visual-hole.com/terminos"><h5>Terminos y Condiciones</h5></a>
            
          </div>
          <div class='footer-link-items'>
          <a href="https://www.visual-hole.com/privacidad"> <h5>Politica de Privacidad</h5></a>
            
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
          <a href="https://www.visual-hole.com/privacidad"> <h5>Politica de cookies</h5></a> 
            
          </div>
          
        </div>
      </div>
      
    </div>
  );
}

export default Footer;
