import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Simple4 from "../Simple4";
import "react-multi-carousel/lib/styles.css";


function Mirandatroudart () {
    const { Mirandatroudart } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Mirandatroudart]);

        

    return (
        <>

        <div className='wraper'>
        <div className='box1'>
        <h2>Manual de marca para Miranda & Troudart Abogados</h2>
        <p>Miranda & Troudart Abogados es una firma de servicios jurídicos constituida como una boutique legal. Los clientes son atendidos directamente por los socios abogados.</p>
        <h3>Fecha</h3>
        <p>Agosto, 2021</p>
       </div>

       <div className='box2'>
       <div className='slider'>
       <span>  <Simple4 deviceType="desktop" />  </span>
        </div>
        
       </div>
</div>






        <Footer/>
        </>
    );
}

export default Mirandatroudart;