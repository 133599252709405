import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

function Spontaneo () {
    const { Spontaneo } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Spontaneo]);
      

    return (
        <>
        <div className='contenido-pr'>
        <div className='row-pr'>
        <div className='col-pr'>
        <h2>Identidad visual para Spontaneo</h2>
        <p>Spontaneo es una marca de Globos, regalos, decoraciones, cajas, t-shirt y mucho más.</p>
        <h3>Fecha</h3>
        <p>Junio, 2021</p>
   
        
        </div>
        <div className='col-pr-r'>
        <div className='imagen-pack-row-pr'> 
                <img class="imagen-pack-row-1-pr" 
                src="/images/portafolio/spontaneo1.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        

        </div>
        
        </div>
        <Footer/>
        </>
    );
}

export default Spontaneo;