import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Simple from "../Simple";
import "react-multi-carousel/lib/styles.css";


function Azucenas () {
    const { Azucenas } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Azucenas]);

        

    return (
        <>

        <div className='wraper'>
        <div className='box1'>
        <h2>Manual de marca para Azucena’s.</h2>
        <p>Azucena’s es una marca de ropa para mujeres de talla grande. Se define por la calidad de sus productos a precios accesibles y atención personalizada.  </p>
        <h3>Fecha</h3>
        <p>Noviembre, 2021</p>
       </div>

       <div className='box2'>
       <div className='slider'>
       <span>  <Simple deviceType="desktop" />  </span>
        </div>
        
       </div>
</div>






        <Footer/>
        </>
    );
}

export default Azucenas;