import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Pagina.css';
import { Button } from '../button';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";

function Pagina () {
    const { Pagina } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Pagina]);

    return (
        <>
            <Helmet>
          <meta charSet='utf-8'/>
          <title>Visual Hole Agency | Pagina Web</title>
          <meta name="description" content= "Diseño web amigable para tu empresa, aplicaciones web, wordpress, seo  " />
          <meta name="robots" content= "index, follow"></meta>
        </Helmet>
        <div className='pagina'>
        <h1>Pagina Web</h1> 
        </div>

  
        <div className='pagina-titulo'>       
         <h2 >Creamos proyectos web que marcan la diferencia.</h2>
        </div>   

        <div className='pagina-centro'>
                        
           
                   <p>Una página web es el mejor escaparate para diferenciarte del resto. Queremos que tu web y 
                     espacio en la red sea diferente, original, único y especial. Creamos y diseñamos webs con vida propia, 
                     personalizables y adaptadas a tu estilo y necesidades.
                      <br/><br/>
                      Háblanos de ti. De tu historia. Cuéntanos quién eres, cuáles son tus sueños. Cómo es tu negocio.
                       Queremos saber tus objetivos para fijar una estrategia. En este arranque trabajaremos una primera
                        estructura de la página web. Ese será nuestro punto de partida.

                      </p>
                      
        </div>

       
        <div className='pagina-row'>

        
        <div className='pagina-col'>
           <div className='pagina-col-content'>   
                   <p>  No te preocupes, no te dejaremos solo. Te explicaremos todo lo referente a tu página web para que luego tú mismo puedas gestionarla.  
                  </p>
                      </div>
</div>
        <div className='pagina-col-r'>
          <div className='pagina-row-ima'> 
                <img class="pagina-imagen-p" 
                src="https://visual-hole.imgix.net/pagina2.jpg" 
                alt="Generic placeholder image"/>
          </div>
        </div>
</div>


      
      
   <div className='pagina-row'>
          <div className='pagina-col2'> 
              <h2>Landing-page</h2>
              <p>  Página web (una sóla página tipo scroll) diseñada para captar la atención y convertir 
              visitantes en clientes potenciales. La característica fundamental de una landing-page es 
              que el usuario accederá a ella solamente a través de un link específico, por eso también 
              se convierte en una opción magnífica si el presupuesto es acotado. </p>
  
            
            </div>
          <div className='pagina-col2'>   
           <h2>Clásica</h2>
           <p>  El tipo de sitio web más común. Consta de un home o página principal más 3 
            secciones. (Por ejemplo: Nosotros, Servicios y Contacto). Proporciona información
             detallada sobre la empresa, productos y servicios.
           </p>
        
          </div>

              <div className='pagina-col2'>   
                  <h2>Tienda Online</h2>
                    <p>  Página web especializada en la venta de productos o servicios en línea, con
                     características como catálogo, carrito de compras y procesos de pago seguros. Es 
                      la opción indicada sobre todo si vendes productos físicos, aunque es igual de indispensable
                     si ofreces servicios.
                   </p>
                 
                </div>
                
                     
  </div>
  <div className='col-button'><a target="_blank" href="https://api.whatsapp.com/send?phone=50761107470&text=Quiero%20cotizar%20Paginaweb"> <button className='bt' >Cotizar</button> </a></div>
                 

  
  



        <Footer/>
        </>
    );
}

export default Pagina;