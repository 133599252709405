import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

function Priscila () {
    const { Priscila } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Priscila]);
      

    return (
        <>
        <div className='contenido-pr'>
        <div className='row-pr'>
        <div className='col-pr'>
        <h2>Identidad visual para Priscila Ortega Franco.</h2>
        <p>Neuropsicóloga.</p>
        <h3>Fecha</h3>
        <p>Enero, 2021</p>
   
        
        </div>
        <div className='col-pr-r'>
        <div className='imagen-pack-row-pr'> 
                <img class="imagen-pack-row-1-pr" 
                src="/images/portafolio/priscilaortega1.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        

        </div>
        <div className='row-pr'>
        <div className='col-pr'>
        
   
        
        </div>
        <div className='col-pr-r'>
        <div className='imagen-pack-row-pr'> 
                <img class="imagen-pack-row-1-pr" 
                src="/images/portafolio/priscilaortega2.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        

        </div>
        </div>
        <Footer/>
        </>
    );
}

export default Priscila;