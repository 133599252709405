import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};
const images = [
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-1.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-2.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-3.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-4.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-5.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-6.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-7.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-8.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-9.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-10.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-11.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-12.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-13.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-14.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-15.jpg",
    "/images/portafolio/JUSTI/JUSTI LEGAL SOLUTIONS-16.jpg"
    
    
];

// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const Simple = ({ deviceType }) => {
  return (
    <Carousel
      ssr
      deviceType={deviceType}
      itemClass="image-item"
      responsive={responsive}
    >
      {images.slice(0, 16).map((image, index) => {
        return (
          <div key={index} style={{ position: "relative" }}>
            <img
              draggable={false}
              alt="text"
              style={{ width: "100%", height: "100%" }}
              src={image}
            />
            <p
              style={{
                position: "absolute",
                left: "100%",
                bottom: 0,
                color: "white",
                transform: " translateX(-50%)"
              }}
            >
              Legend:{index}.
            </p>
          </div>
        );
      })}
    </Carousel>
  );
};

export default Simple;
