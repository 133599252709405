import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Asesoria.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import FormContent from '../FormContent';
import Fomulario from '../Formulario';
function Asesoria () {

    const { Asesoria } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [Asesoria]);

    return (
        <>
         <Helmet>
          <meta charSet='utf-8'/>
          <title>Visual Hole Agency | Asesoria</title>
          <meta name="description" content= "¿Cómo saber si tu marca funciona? ideas, guías, ejemplos prácticos para impulsar tu contenido" />
          <meta name="robots" content= "index, follow"></meta>
          
        </Helmet>

        <div className='header'>
       
        <h1> Asesoria</h1> 
      
       
        <p> ¿Estás buscando ayuda para lograr los objetivos de tu marca?</p> 
        
        </div>
        <div className='contenido-ase'>
        <div className='row-ase'>
        <div className='col-ase'>
        <div className='imagen-pack-row-ase'> 
                <img class="imagen-pack-row-1-ase" 
                src="https://visual-hole.imgix.net/yoselin8.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        <div className='col-ase-r'>
        <h1>Asesorias</h1>
        <h2>esta asesoria es para ti si eres...</h2>
        <p>Un emprendedor, marca personal, empresa con trayectoria, blogger o si tienes un proyecto 
            y no sabes cómo organizarte, por dónde empezar con tus objetivos ni mucho menos seguir la 
            estética de tus Redes Sociales…</p>
        
        
        </div>

        </div>
        
        </div>
        <div className='contenido-centro'>
            <h1>Cómo puedo Ayudarte?</h1>
            <p>Cuando inicié Visual Hole Agency me hubiese gustado saber por dónde empezar. 
                Tener una guía o conocer la experiencia de alguien. Pero cada uno de los errores 
                o situaciones difíciles me ayudaron a crecer como profesional. </p>
                <p>¡Hola, soy Yoselin Goncalves! Creadora de Visual Hole Agency y estoy dispuesta 
            a acompañarte en el camino de tu proyecto. Te ayudaré a identificar tus debilidades
            y fortalezas, tus posibles errores para convertirlos en aciertos. Me basaré en tus 
            valores de marca para poder darte una asesoría completa sobre la imagen visual de ella.
            Analizaré tu comunicación digital para entender mejor tu situación. Lo primero que haremos
            es pautar una llamada para conocer tu marca. Luego vamos a pautar otra reunión en la que te 
            explicaré todo lo que haremos (con guías prácticas). Así puedo darte una asesoría
            relacionada a tu sector. </p>
        </div>
        
        <div className='contenido-ase2'>
        <div className='form-centro'>
        <div className='col-ase2'>
        <h1>Vamos por pasos</h1>
        <h2>BRIEFING – VIDEOLLAMADA</h2>
        <p>Tendremos una llamada para poder conocer tu marca. </p>
        <h2>ASESORÍA – VIDEOLLAMADA</h2>
        <p>La asesoría de 50 min. </p>
        <p><br/><a target="_blank" href="https://api.whatsapp.com/send?phone=50761107470&text=Quiero%20Asesoria"> <button className='bt' >Asesoria ya.</button> </a></p>
        </div>
        <div className='col-ase-r2'>
        
        
        
        </div>

        </div>
        
        </div>
    
        <div className='contenido-ase3'>
        <div className='row-ase3'>
        <div className='col-ase3'>
        <h1>Qué incluye? </h1>
        
        <p>            
            <ul>
            <li>Dos (2) videollamadas de 50 minutos para encontrar las fallas, <br/>
                análisis de tu proyecto, aconsejarte y darte las herramientas<br/> adecuadas para tu marca.</li>
            <li> Una llamada para el BRIEFING y la otra para la asesoría.</li>
            <li>Grabación de la videollamada.</li>
            <li>Herramientas claves.</li>
            <li>Guía, inspiración, material de apoyo.</li>
            
            </ul>
            </p>
           
        </div>
        <div className='col-ase-r3'>
        <h1>Qué obtendrás?</h1>
            <p>
                <ul> 
                <li>Identificar si tu branding funciona y es coherente con tu marca. </li>
                <li>Importancia de la identidad definida.</li>
                <li>Contenido que debes publicar según tu área.</li>
                <li>Cómo mantener armonía y estética en tu feed..</li>
                
                <li>Encontrar ideas nuevas para tu contenido.</li>
                <li>App y programas para impulsar tu contenido.</li>
                <li>Cómo tomar tus fotografías según tu sector.</li>
                <li>Estrategias de ventas.</li>
                
                <li>Debilidades y fortalezas de tu marca</li>
                <li>Resolveremos los problemas internos que puedas tener.</li>
                <li>Resolveremos tus dudas</li>
               
                </ul>
            </p>
        
        
        </div>

        </div>
        <div>



            
        </div>
        
        </div>
        <h1 className='titulo-agenda'>¡Agenda con nosotros tu asesoria ahora!</h1>
        <div className='form-centro'>
           
       

         
      
        </div>
     
          
        <Footer/>
        </>
    );
}

export default Asesoria;