import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Simple from "../Simple11";
import "react-multi-carousel/lib/styles.css";


function Finca () {
    const { Finca } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Finca]);

        

    return (
        <>

        <div className='wraper'>
        <div className='box1'>
        <h2>Manual de marca para Finca Maria Bonita</h2>
        <p>Finca Maria Bonita es una granja que su principal producto es la leche de cabra y sus derivados.</p>
        <h3>Fecha</h3>
        <p>Noviembre, 2022</p>
       </div>

       <div className='box2'>
       <div className='slider'>
       <span>  <Simple deviceType="desktop" />  </span>
        </div>
        
       </div>
</div>






        <Footer/>
        </>
    );
}

export default Finca;