import React from 'react'
import '../../App.css';
import Herosection from '../Herosection';
import Portafolio2 from '../Portafolio2';
import Footer from '../Footer';
import ThreeColumnWithProfileImage from '../ThreeColumnWithProfileImage';
import Towcol from '../Towcol';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";

function Home () {

    const { Home } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [Home]);


    
    return (
        <>
        <Helmet>
          <meta charSet='utf-8'/>
          <title>Visual Hole Agency | Estudio Creativo</title>
          <meta name="description" content= "Agencia de Marketing Digital. Branding, Redes Sociales, Asesoría, Business Intelligence, Páginas web, SEO" />
          <meta name="robots" content= "index, follow"></meta>
        </Helmet>
        <Herosection/>
        <Towcol/>
        
        <Portafolio2/>
        
        
        <Footer/>
        
        </>
    );
}

export default Home;