
import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Simple3 from "../Simple3";
import "react-multi-carousel/lib/styles.css";


function Danielad () {
    const { Danielad } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Danielad]);

        

    return (
        <>

        <div className='wraper'>
        <div className='box1'>
        <h2>Manual de marca para Daniela Diaz</h2>
        <p>Daniela Diaz es una coach de nutrición que te ayuda a crear hábitos saludables para tu estilo de vida.</p>
        <h3>Fecha</h3>
        <p>Septiembre, 2021</p>
       </div>

       <div className='box2'>
       <div className='slider'>
       <span>  <Simple3 deviceType="desktop" />  </span>
        </div>
        
       </div>
</div>






        <Footer/>
        </>
    );
}

export default Danielad;