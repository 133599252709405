import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import CardItem from '../CardItem';
import './Portafolio.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";

function Portafolio () {
  const { Portafolio } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Portafolio]);

    return (
        <>
         <Helmet>
          <meta charSet='utf-8'/>
          <title>Visual Hole Agency | Portafolio</title>
          <meta name="description" content= "Conoce nuestro trabajo de manual de marca, pagina web, papeleria, diseño grafico" />
          <meta name="robots" content= "index, follow"></meta>
        </Helmet>
        <div className='header'>
    
        <h1> Portafolio</h1> 

      
        </div>

        <div className='contenido-centro'>
        <h1>Proyectos que hemos hecho</h1>
        <p>Una pequeña muestra de nuestro trabajo.</p>
        <p>Branding - diseño</p>

        </div>

        <div className='portafolio-contenido'>
        <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items'> 

        <CardItem
              src='images/santarita-01.jpg'
              text='Santa Rita'
              
              path='/Santarita'
            /> 
            <CardItem
            src='images/mercadovera-01.jpg'
            text='Mercado de Vera'
            
            path='/Mercadodevera'
          />
          </ul> 
           
           <ul className='cards__items'>
           
           
            <CardItem
              src='images/photozulem-01.jpg'
              text='Katherine Zulem'
             
              path='/Photozulem'
            />
            <CardItem
              src='images/zulemevent-01.jpg'
              text='Zulem Events'
              
              path='/Zulemevents'
            />
          </ul>
          <ul className='cards__items'>
            
            <CardItem
              src='images/fincamariabonita-01.jpg'
              text='Finca Maria Bonita'
             
              path='/Finca'
            />
            <CardItem
              src='images/neoseg-01.jpg'
              text='Neo Seguros'
              
              path='/Neoseguros'
            />
          </ul>
          <ul className='cards__items'>
            
            <CardItem
              src='images/danielad-01.jpg'
              text='Daniela Diaz'
             
              path='/Danielad'
            />
            <CardItem
              src='images/bloomn-01-01.jpg'
              text='Bloom Naturals'
              
              path='/Bloom'
            />
          </ul>
          <ul className='cards__items'>
            
            <CardItem
              src='images/Troudart-01-01.jpg'
              text='Troudart Law'
             
              path='/Troudart'
            />
             <CardItem
              src='images/bambolina-01.jpg'
              text='Bambolina'
              
              path='/Bambolina'
            />
          </ul>
          <ul className='cards__items'>
           
            <CardItem
              src='images/spontaneo.jpg'
              text='Spontaneo'
             
              path='/Spontaneo'
            />
             <CardItem
              src='images/eliana.jpg'
              text='Eliana Interior Desing'
              
              path='/Eliana'
            />
          </ul>
          <ul className='cards__items'>
           
            <CardItem
              src='images/Yosfisiofit.jpg'
              text='Yosfisiofit'
             
              path='/Yosfisiofit'
            />
            <CardItem
              src='images/iluminando-01.jpg'
              text='Iluminando el futuro'
              
              path='/Iluminando'
            />
          </ul>
          <ul className='cards__items'>
            
            <CardItem
              src='images/justilegal-01.jpg'
              text='Justi Legal Solution'
             
              path='/Justi'
            />
             <CardItem
              src='images/priscila.jpg'
              text='Priscila Ortega Franco'
              
              path='/Priscila'
            />
          </ul>
          <ul className='cards__items'>
           
            <CardItem
              src='images/group-01.jpg'
              text='Prime Alliance Group'
             
              path='/Prime'
            />
            <CardItem
              src='images/fc.jpg'
              text='Feliz Compras'
              
              path='/Fc'
            />
          </ul>
          <ul className='cards__items'>
            
            <CardItem
              src='images/eleden.jpg'
              text='Jardinería El Edén'
             
              path='/eleden'
            />
            <CardItem
              src='images/musa.jpg'
              text='La Musa, moda y estilo'
              
              path='/Musa'
            />
          </ul>
          <ul className='cards__items'>
            
            
          </ul>
          </div>
          </div>
</div>
        <Footer/>
        </>
    );
}

export default Portafolio;