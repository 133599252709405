import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Proyectos.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

function Musa () {
    const { Musa } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Musa]);
      

    return (
        <>
        <div className='contenido-pr'>
        <div className='row-pr'>
        <div className='col-pr'>
        <h2>Identidad visual para <br/> La musa, moda y estilo.</h2>
        <p>La musa, moda y estilo es una marca venezolana enfocada en la creación de vestidos de novia y de quinceañeras. </p>
        <h3>Fecha</h3>
        <p>Febrero, 2020</p>
   
        
        </div>
        <div className='col-pr-r'>
        <div className='imagen-pack-row-pr'> 
                <img class="imagen-pack-row-1-pr" 
                src="/images/portafolio/lamusa1-min.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        

        </div>
        
        </div>
        <Footer/>
        </>
    );
}

export default Musa;