import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import './Contenido.css';
import { Button } from '../button';
import Portafolio2 from '../Portafolio2';
import CardItem from '../CardItem';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";

function Contenido () {
        const { Contenido } = useLocation();
  
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [Contenido]);
      

    return (
        <>
        <Helmet>
          <meta charSet='utf-8'/>
          <title>Visual Hole Agency | Creacion de Contenido</title>
          <meta name="description" content= "Estrategias y diseño gráfico para tus redes sociales adaptados a tu presupuesto" />
          <meta name="robots" content= "index, follow"></meta>
        </Helmet>
       <div className='header'>
       
        <h1> Creación de Contenido</h1> 
      
        <p> Comunicar, decir algo, transformar cosas… todo eso y más es fundamental para tu marca.</p> 
      
      
        </div>

        <div className='container'>
                  
                  <p>Creamos contenido y estrategias únicas para tu marca. La comunicación digital es muy importante.
                  <br/>Además de mantener la estética de tus redes sociales, es saber cómo comunicarte con tu audiencia. Entonces, 
                  </p>
               
                  <h2 >Cómo lograr la comunicación adecuada?</h2>
                   <p>Bueno, empezando por estudiar tu marca, sus fortalezas y valores. <br/>
                      Trabajar en las ideas para que logres lo que estás esperando. <br/>
                      Con mi equipo de trabajo, podemos hacerlo.
                      </p>
  </div>

       <div className='content-pack'>
<div className=' row-pack-01'>

        <div className='col-pack-01'>
        <div className='imagen-pack-row-c'> 
                <img class="imagen-pack-row-1" 
                src="https://visual-hole.imgix.net/contenido2.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        <div className='col-pack-01-r'>
        <div className='col-title'>
            <h1> Pack Mensual</h1> 
            </div>
                    <div className='col-content'>   
                    <ul>
                    <li>Reunión inicial</li>
                    <li>Análisis de identidad</li>
                    <li>Análisis de redes.</li>
                    <li>Análisis de la competencia</li>
                    <li>Público objetivo</li>
                    <li>Calendario</li>
                    <li>Presentación de la propuesta mensual</li>
                    <li>Creación de post e historias</li>
                    <li>Diseño de feed</li>
                    <li>Elección de paleta de colores (si no posee)</li>
                    <li>Elección de tipografías</li>
                    <li>Estilo fotográfico</li>
                    <li>Copy</li>
                  

                    </ul>
                    <p>*La fotografía y los videos dependerán del presupuesto</p>
                    </div>
                    <div className='col-time'>   
                    <h4></h4>
                    </div>
                    
                    <div className='col-buttonco'><a target="_blank" href="https://api.whatsapp.com/send?phone=50761107470&text=Quiero%20comprar%20Pack1"> <button className='bt' >Comprar</button> </a></div>
                    
            
        </div>
</div>





        <div className='row-pack-02'>
        <div className='col-pack-02'>
                        <div className='col-title'>
                                <h1> Pack Plantillas</h1> 
                        </div>
                    <div className='col-content'>   
                    <ul>
                    <li>Plantillas para feed</li>
                    <li>Plantillas historias o reels.</li>
                    <li>Íconos ig highlights</li>
                    <li>Íconos</li>
                    <li>Recursos gráficos</li>
                    <li>Elección de colores (si no posee)</li>
                    <li>Elección de tipografías.</li>
                    <li>Estilo fotográfico</li>
                    <li>Archivos editables</li>
                    </ul>
                    <p>Si tu proyecto necesita ir más allá, contáctanos y creamos un plan a la medida.</p>
                    </div>
                    <div className='col-time'>   
                    <h4></h4>
                                        </div>
                    <div className='col-buttonco'><a target="_blank" href="https://api.whatsapp.com/send?phone=50761107470&text=Quiero%20comprar%20Pack2"> <button className='bt' >Comprar</button> </a></div>
                          
        </div>
        <div className='col-pack-02-r'>
        <div className='imagen-pack-row-c'> 
                <img class="imagen-pack-row-2" 
                src="https://visual-hole.imgix.net/contenido1.jpg" 
                alt="Generic placeholder image"/>
         </div>
        </div>
        </div>

        <div className=' row-pack-01'>




</div>


        </div>

<div className='portafolio-contenido'>
        <div className='cards__container'>
        <div className='cards__wrapper'>
           <ul className='cards__items'>
           <CardItem
              src='images/santarita-01.jpg'
              text='Santa Rita'
              
              path='/Santarita'
            />
         
         
          <CardItem
              src='images/mercadovera-01.jpg'
              text='Mercado de Vera'
              
              path='/Mercadodevera'
            />
            <CardItem
              src='images/photozulem-01.jpg'
              text='Katherine Zulem'
             
              path='/Photozulem'
            />
           <CardItem
              src='images/zulemevent-01.jpg'
              text='Zulem Events'
              
              path='/Zulemevents'
            />
            <CardItem
             src='images/fincamariabonita-01.jpg'
             text='Finca Maria Bonita'
            
             path='/Finca'
            />
          </ul>
          </div>
          </div>
</div>
        <Footer/>
        </>
    );
}

export default Contenido;