import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Button } from './button';
import './NavBar.css';

function NavBar() {
    const[click, setClick] = useState(false);
    const [button, setButton] = useState(true)

    const handleClick =() => setClick (!click);
    const closemobilmenu =() => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960) {
        setButton(false);
        } else{
        setButton(true);

        }
    };
useEffect(()=>{
    showButton()
},[]);
    window.addEventListener('resize', showButton);

  return (
   <>
  
   <nav className='navbar'>

       <div className='navbar-container'>
       {/* <div className='tienda-button'>{button && < Button buttonstyle ='btn--outline'> Tienda</Button>}</div> */}
       
        <Link to ="/" className={button ? 'navbar-logo' : 'navbar-logo-mob'} onClick={closemobilmenu}>
        
        <img src={require('./logocolornofondo.png')} alt='logo' width={150}/>
        
        </Link>
        
        
      
        <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        
       <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
               <Link to ='/branding' className='nav-links' onClick={closemobilmenu}>
                   Branding
               </Link>
           </li>
           <li className='nav-item'>
               <Link to ='/contenido' className='nav-links' onClick={closemobilmenu}>
                   Creación de contenido
               </Link>
           </li>
           <li className='nav-item'>
               <Link to ='/asesoria' className='nav-links' onClick={closemobilmenu}>
                   Asesoría
               </Link>
           </li>
           <li className='nav-item'>
               <Link to ='/portafolio' className='nav-links' onClick={closemobilmenu}>
                   Portafolio
               </Link>
           </li>
           <li className='nav-item'>
               <Link to ='/pagina' className='nav-links' onClick={closemobilmenu}>
                   Pagina Web
               </Link>
           </li>
        
           <li className='nav-item'>
               <Link to ='/Ti' className='nav-links' onClick={closemobilmenu}>
               Transformación Digital
               </Link>
           </li>
           <li className='nav-item'>
               <Link to ='/sobremi' className='nav-links' onClick={closemobilmenu}>
                   Sobre nosotros
               </Link>
           </li>    
           {/* <li>
              <Link
                to='/tienda'
                className='nav-links-mobile'
                onClick={closemobilmenu}
              >
                Tienda
              </Link>
            </li>        */}
       </ul>
       
       </div>

       
   </nav>
   </>
  )
}

export default NavBar