import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};
const images = [
    "/images/portafolio/santarita1.png",
    "/images/portafolio/santarita2.png",
    "/images/portafolio/santarita3.png",
    "/images/portafolio/santarita4.png",
    "/images/portafolio/santarita5.png",
    "/images/portafolio/santarita6.png",
    "/images/portafolio/santarita7.png",
    "/images/portafolio/santarita8.png",
    "/images/portafolio/santarita9.png",
    "/images/portafolio/santarita10.png",
    "/images/portafolio/santarita11.png",
    "/images/portafolio/santarita12.png",
    "/images/portafolio/santarita13.png",
    "/images/portafolio/santarita14.png",
    "/images/portafolio/santarita15.png",
    "/images/portafolio/santarita16.png",
    "/images/portafolio/santarita17.png",
    "/images/portafolio/santarita18.png",
    "/images/portafolio/santarita19.png",
    "/images/portafolio/santarita20.png",
    "/images/portafolio/santarita21.png",
    "/images/portafolio/santarita22.png",
    "/images/portafolio/santarita23.png"
    
];

// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const Simple = ({ deviceType }) => {
  return (
    <Carousel
      ssr
      deviceType={deviceType}
      itemClass="image-item"
      responsive={responsive}
    >
      {images.slice(0, 23).map((image, index) => {
        return (
          <div key={index} style={{ position: "relative" }}>
            <img
              draggable={false}
              alt="text"
              style={{ width: "100%", height: "100%" }}
              src={image}
            />
            <p
              style={{
                position: "absolute",
                left: "100%",
                bottom: 0,
                color: "white",
                transform: " translateX(-50%)"
              }}
            >
              Legend:{index}.
            </p>
          </div>
        );
      })}
    </Carousel>
  );
};

export default Simple;
