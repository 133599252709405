import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import { Button } from '../button';
import './Branding.css';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import CardItem from '../CardItem';
import {Helmet} from "react-helmet";
import Portafolio2 from '../Portafolio2';
    

function Branding () {
    
    const { Branding } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [Branding]);
  

    return (
        <>

          <Helmet>
          <meta charSet='utf-8'/>
          <title>Visual Hole Agency | Branding</title>
          <meta name="description" content= "Diseño y creación de Logo, Branding ideal para tu marca" />
          <meta name="robots" content= "index, follow"></meta>
          </Helmet>

        <div className='branding-home'>
       
        <h1> Branding</h1> 
           
        <p> El objetivo es conquistar no solo la mente del consumidor, sino también su corazón.</p> 
        
        </div>
        <div className='container'>
                  
                        <p>Branding (o gestión de marca) son acciones alineadas al posicionamiento, 
                        propósito y valores de una marca. <br/>Su objetivo es despertar sensaciones y 
                        crear conexiones conscientes e inconscientes, que serán cruciales para que
                        el cliente elija su marca en el momento de decisión de compra. 
                        </p>
                     
                    <h2 >Piensa por 1 minuto en una marca.<br/> Ya la tienes?</h2>
                         <p>Además de pensar en su nombre, colores, letras, formas y sonidos 
                            ¿has pensado en las sensaciones y recuerdos que produce?
                            Esa es exactamente la función del branding: despertar sensaciones y crear conexiones
                            conscientes e inconscientes, que serán cruciales para que el cliente elija tu marca
                            en el momento de compra.
                            </p>
        </div>
       
        <div className='container-01'>
                <h1> Conoce nuestros paquetes de branding</h1>
                
        </div>
<div class="container-towcol">
  <h1>¿Estás listo para transformar tu negocio?</h1>
  <div class="row-towcol">
    <div class="col-sm">
    <img class="imagen-col" 
    src="https://visual-hole.imgix.net/branding2.jpg" 
    alt="Generic placeholder image"/>
    </div>
    <div class="col-sm-r">
    <div className='col-title'>
            <h1> Paquete Standard</h1> 
            </div>
                    <div className='col-content'>   
                    <ul>
                    <li>Diseño de logo y sus variantes </li>
                    <li>Tipografías</li>
                    <li>Paleta de colores</li>
                    <li>Moodboard</li>
                    <li>Highlights (6 iconos)</li>
                    <li>Diseño 6 plantillas para redes sociales.</li>
                    <li>Objetivos y palabras claves de tu marca.</li>
                    
                    <li>Manual de Identidad y Estilo</li>
                    <li>Diseño de tarjeta de presentación (1)</li>
                    </ul>
                    </div>
                    <div className='col-time'>   
                    
                    </div>
                    
                    <div className='col-buttonbr'><a target="_blank" href="https://api.whatsapp.com/send?phone=50761107470&text=Quiero%20comprar%20paquetestandard"> <button className='bt' >Cotizar</button> </a></div>
                    <div className='col-title-r'>
            <h1> Paquete Brandbook </h1> 
            </div>
                    <div className='col-content-r'>   
                    <ul>                          
                   <li>Diseño de logo y sus variantes</li> 
                   <li>Tipografías</li> 
                   <li>Paleta de colores</li> 
                   <li>Moodboard</li> 
                   <li>Highlights (6 iconos)</li> 
                   <li>Diseño 6 plantillas para redes sociales.</li> 
                   <li>Objetivos y palabras claves de tu marca.</li> 
                   
                   <li> Historia de la marca</li>
                   <li>Misión y visión</li> 
                   <li>Papelería (6 diseños, por ej: stickers, empaque, hoja membretada)</li> 
                   <li>Manual de Identidad y Estilo</li> 
                   <li>Diseño de tarjeta de presentación (1)</li> 
                 </ul>
                    </div>
                    <div className='col-time-r'>   
                    
                    </div>
                    
                    <div className='col-buttonbr'><a target="_blank" href="https://api.whatsapp.com/send?phone=50761107470&text=Quiero%20comprar%20Brandbook"> <button className='bt' >Cotizar</button> </a></div>
                    <div className='col-title-r'>
            <h1>Papeleria </h1>
            </div>
                    <div className='col-content-r'>   
                    <ul>                          
                   <li>Tarjetas de presentación</li>
                   <li>Sobres</li>
                   <li>Stickers</li>
                   <li>Folders</li>
                   <li>Hangtags</li>
                   <li>Merch</li>
                   <li>Carta/Menú</li>
                   <li>Afiches</li>
                   <li>Flyers</li>
                   <li>Entre otros</li>
                   <li>Invitaciones</li>
                   <li>Cajas</li>
                   
                 </ul>
                    </div>
                    <div className='col-time-r'>   
                    
                    </div>
                    
                    <div className='col-buttonbr'><a target="_blank" href="https://api.whatsapp.com/send?phone=50761107470&text=Quiero%20comprar%20Brandbook"> <button className='bt' >Cotizar</button> </a></div>
      </div>
    
</div>
<div className='col-full'> 
    <h1>¿Cómo podemos ayudarte? </h1>
    <h2>Quiero conocer la historia de tu marca</h2>
    <p>Para poder comunicar una buena estrategia, necesito saber tu historia y cómo podemos sacar provecho de ella.<br/> 
      <br/>Nos reunimos de modo online, hablamos, te escucho, y anoto todo lo que necesitas. <br/>
      <br/>Luego seguimos con los siguientes pasos dependiendo del servicio que requieres. <br/>
      <br/>Buscamos impulsar a emprendedores, medianas y grandes empresas a que puedan conseguir un negocio visible, 
      estético y rentable, asesorándolos y diseñando una marca única, funcional y moderna.</p>
      <div className='row-button-home'>{Button && < Button to='/Asesoria' buttonstyle ='btn--outline'> Asesoría</Button>}</div>
      
      
      </div>
      
        </div>

        <Portafolio2/>
        

               
        <Footer/>
        </>
    );
}

export default Branding;

