import React from 'react';
import { Button } from './button';
import './Herosection.css';
import '../App.css';



function Herosection() {
  return (
    <div className='hero-container'>
        {/* 
<video src="/video/home.mp4" autoPlay loop muted /> */}

<p>Somos un estudio creativo de identidad y estrategia de marca. Creamos un mundo visual y comunicativo donde tu audiencia sentirá que la marca está hecha para ellos. </p>
<div className='hero-btns'>

         <a target="_blank" href="https://api.whatsapp.com/send?phone=50761107470&text=Quiero%20mas%20informacion"> <button className='bt' >Empecemos</button> </a>
         
</div>
    </div>
  )
}

export default Herosection