import React from 'react';
import '../../App.css';
import './Sobremi.css';
import Footer from '../Footer';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Button } from '../button';
import ThreeColumnWithProfileImage from '../ThreeColumnWithProfileImage';
import {Helmet} from "react-helmet";

function Sobremi () {
    const { Sobremi } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Sobremi]);

    return (
        <>
         <Helmet>
          <meta charSet='utf-8'/>
          <title>Visual Hole Agency | Sobre nosotros</title>
          <meta name="description" content= "Experiencia en estrategias digitales, negocios, diseño gráfico, asesoría de marketing, copywriting" />
          <meta name="robots" content= "index, follow"></meta>
        </Helmet>
        <div className='header'> 
        <h1>Sobre nosotros</h1> 
        </div>

        <div className='sobre'>
        <div className='sobre-row'>

        
        <div className='sobre-col'>
           <div className='sobre-col-content'>   
                   <p>  Hola, me llamo Yoselin Goncalves y creé Visual Hole Agency porque necesitaba hacer algo diferente con mi vida.
                        O al menos, divertirme con lo que hago. Estaba en un trabajo en el que no era feliz, y constantemente me sentía
                        en una especie de agujero negro. Poco a poco fui obteniendo algunos clientes con los que trabajaba como Social 
                        Media y al cabo de unos meses, decidí crear un proyecto con más forma. Un estudio creativo enfocado en el marketing digital. <br/><br/>
                        Pero ¿por qué Visual Hole?<br/><br/>
                       Buscar el nombre de mi marca fue un poco complicado porque quería algo único, tal vez mezclado con mi otra carrera como escritora.
                       Llegué al nombre de Visual Hole después de ver una charla sobre la publicidad, en ella explicaban que el marketing era como un 
                       enorme agujero de posibilidades visuales. Me pareció algo tremendo y jugando con las palabras, llegué a su nombre. 
                  </p>
                      </div>
</div>
        <div className='sobre-col-r'>
          <div className='sobre-row-ima'> 
                <img class="sobre-imagen-p" 
                src="https://visual-hole.imgix.net/yoselin9.jpg" 
                alt="Generic placeholder image"/>
          </div>
        </div>
</div>

</div>
<div className='sobre-centro'>
                  
                              
                
                      <h2 >Quiénes me acompañan?</h2>
  </div>

  <div className='sobre'>
        <div className='sobre-row'> 

        
        <div className='sobre-col'>
        <div className='sobre-row-ima'> 
                <img class="sobre-imagen-r" 
                src="https://visual-hole.imgix.net/Juan2.jpg" 
                alt="Generic placeholder image"/>
          </div>
</div>
        <div className='sobre-col-r'>

        <div className='sobre-col-content'>   
                   <p>  Mi nombre es Juan Hernandez, y soy profesional con más de 10 años experiencia en tecnología de computación y manejo de proyectos,
                      orientado a resultados medibles y efectivos. Amplia experiencia en el análisis de sistemas, manejo de datos, transformacion digital y seguridad.
                       Habilidades avanzadas en sistemas operativos empresariales: manejo, implementación, diseño y solución de problemas. Experto en la creación 
                       e implementación de estrategias para business intelligence. Vasto conocimiento para identificar vías que permiten el desarrollo 
                       de proyectos de manera fácil y comprensible para clientes y el equipo de trabajo.  
                  </p>
                      </div>
      
        </div>
</div>

</div>
<div className='sobre'>
        <div className='sobre-row'> 

        
        <div className='sobre-col'>
        <div className='sobre-row-ima'> 
                <img class="sobre-imagen-r" 
                src="https://visual-hole.imgix.net/efrain.jpg" 
                alt="Generic placeholder image"/>
          </div>
</div>
        <div className='sobre-col-r'>

        <div className='sobre-col-content'>   
                   <p>  Soy Efrain, Ingeniero en Informática con una sólida experiencia de 10 años liderando equipos en el
                         ámbito de desarrollo de software, infraestructura. Me destaco por ofrecer soluciones tecnológicas
                          de vanguardia respaldadas por un amplio conocimiento en Ingeniería de Software, Infraestructura, 
                          Redes, Nube (Azure) y Ciencia de Datos. La profundidad de mi conocimiento técnico se complementa 
                          con una destacada experiencia en la gestión de tecnología, respaldada por una maestría en finanzas.
                           Fusiono habilidades técnicas y visión estratégica para proporcionar soluciones tecnológicas eficientes 
                           y efectivas, liderando el camino hacia la transformación digital y la innovación en cada proyecto.  
                  </p>
                      </div>
      
        </div>
</div>

</div>
<div className='sobre'>
        <div className='sobre-row'>

        
        <div className='sobre-col'>
        <div className='sobre-row-ima'> 
                <img class="sobre-imagen-r" 
                src="https://visual-hole.imgix.net/Leidy5-01.jpg" 
                alt="Generic placeholder image"/>
          </div>
        
           
</div>
        <div className='sobre-col-r'>
        <div className='sobre-col-content'> 
        <p>  Mi nombre es Leidy Arias, diseñadora gráfica. Tengo amplia experiencia en diseño gráfico, 
                     he sido profesora y pasante en un departamento Editorial en Venezuela. Trabajo con la Agencia Visual Hole desde el 2019.
                      Me caracterizo por ser una mujer dinámica, detallista, responsable, perfeccionista en los detalles, creativa y comprometida. 
                      Me gusta la simplicidad, la manera en cómo se puede comunicar sin la necesidad de sobrecargar el diseño. Mis trabajos se caracterizan 
                      por tener un enfoque minimalista y funcional. Tengo una gran capacidad para trabajar en equipo,
                       estoy dispuesta a asumir retos, escuchar y aprender de los demás. 
                  </p>
        </div>
        
                     
        </div>
</div>

</div>


        <Footer/>
        </>
    );
}

export default Sobremi;